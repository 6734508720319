<template lang="pug">
div(v-if='data' class='space-y-12 content')
  ProductExtraInfoDescription(:data='data')

  template(v-if='data.specifications || data.howItWorks || data.additionalFeatures || data.faq')
    ProductExtraInfoDetails(id='scrollTarget-details' :data='data')

  template(v-if='data.videos?.videos')
    h2(id='scrollTarget-videos') Videos
    ProductExtraInfoVideoList(:videos='data.videos.videos' section='videos' :grid-cols='gridCols')

  ProductExtraInfoInstallation(v-if='data.installation' id='scrollTarget-install' :data='data')

  ProductExtraInfoWarranty(v-if='data.warranty' id='scrollTarget-warranty' :data='data')

  div(class='space-y-6 md:space-y-12')
    ConstructorioABTest(class='max-w-6xl m-auto')
      template(#original)
        //- RELATED/RECOMMENDED PRODUCTS
        CarouselProduct(
          v-if='hasRelatedProducts && $sitewideConfig.config.recommendedProductsEnabled'
          product-type='related'
        )
        CarouselProduct(v-else-if='$sitewideConfig.config.recommendedProductsEnabled' product-type='recommended')

      template(#constructor)
        ConstructorioRecommendations(pod-id='product-page-1' :item-id='data.productLineSlug')
    SimpleAccordion(
      v-if='yotpoEnabled && hasCustomerPhotos && !!$sitewideConfig.config.yotpoProductGalleryId'
      id='scrollTarget-photos'
      header='Customer Photos'
    )
      h2(v-if='!$device.isMobile') Customer Photos
      YotpoCustomerPhotos(:yotpo-id='data.yotpoId' :gallery-id='$sitewideConfig.config.yotpoProductGalleryId')

    SimpleAccordion(v-if='yotpoEnabled' id='scrollTarget-reviews' header='Reviews')
      h2(v-if='!$device.isMobile') Reviews
      YotpoReviews(:yotpo-id='data.yotpoId' :yotpo-name='data.name')

    SimpleAccordion(v-if='yotpoEnabled' id='scrollTarget-qa' header='Q&A')
      h2(v-if='!$device.isMobile') Q&amp;A
      YotpoQuestions(:yotpo-id='data.yotpoId' :yotpo-name='data.name')
</template>
<script setup lang="ts">
const { $device, $sitewideConfig } = useNuxtApp()
const recStore = useRecommendationsStore()
const { isIntegrationEnabled } = useUtils()
const yotpoEnabled = isIntegrationEnabled('yotpo')

const hasRelatedProducts = computed(() => !!recStore.state.related)

const { hasCustomerPhotos, data } = defineProps<{
  hasCustomerPhotos: boolean
  data: ProductLine
}>()

const gridCols = computed(() => {
  if ($device.value.isMobile) return 1
  if (!$device.value.isDesktop) return 2
  return data.videos?.videos && data.videos.videos.length > 2 ? 4 : 2
})
</script>
